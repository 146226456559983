import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { themeStyles, presets } from '../utils/theme'
import TitleBar from '../components/TitleBar'


const styles = {
  container: {
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    [presets.Tablet]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    }
  },
  featuredImage: {
    minWidth: '270px',
    marginLeft: '0px',
    marginBottom: '20px',
    [presets.Tablet]: {
      marginBottom: '0px',
      marginLeft: '20px',
    }
  }
}

export const JobPageTemplate = ({
  content,
  contentComponent,
  helmet,
  title,
  location,
}) => {
  const PostContent = contentComponent || Content

  return (
    <div>
      <TitleBar
        title={title}
      />
      <section className="section" css={styles.container}>
        {helmet || ''}
        <PostContent content={content} />
      </section>
    </div>
  )
}

JobPageTemplate.propTypes = {
  content: PropTypes.object.isRequired,
  contentComponent: PropTypes.func,
  helmet: PropTypes.object,
  title: PropTypes.string,
  location: PropTypes.string,
}

const JobPage = ({ data }) => {
  const { markdownRemark: post } = data
  const description = `Join us as ${post.frontmatter.title} and help us to improve every American's financial situation.`

  return (
    <Layout metadataTitle={post.frontmatter.title} metadataDescription={description}>

      <JobPageTemplate
        content={post.htmlAst}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        location={post.frontmatter.location}
        helmet={
          <Helmet titleTemplate="%s | Careers">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  )
}

JobPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default JobPage

export const pageQuery = graphql`
  query JobPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        location
      }
    }
  }
`
